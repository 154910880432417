body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Heebo', sans-serif;
  background-color: #020a20;
  color: white;
  text-align: center;
  position: relative;
}

.background-oval-left {
  background-color: #000f4b;
  border-radius: 50px; /* Makes the edges oval */
  padding: 140px 280px; /* Padding inside the oval */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Optional shadow for a better look */
  position: absolute; /* Absolute positioning */
  left: 100px; /* Distance from the left edge */
  top: 220px; /* Distance from the top edge */
}

.background-oval-right {
  background-color: #000f4b;
  border-radius: 50px; /* Makes the edges oval */
  padding: 140px 300px; /* Padding inside the oval */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Optional shadow for a better look */
  position: absolute; /* Absolute positioning */
  left: 1000px; /* Distance from the left edge */
  top: 220px; /* Distance from the top edge */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  text-align: center;
  padding: 10px 0;
  background-color: #020a20;
  color: white;
  width: 100%;
  flex-shrink: 10;
}

.config-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 40px;
}

h1 {
  font-size: 80px;
  margin-top: 100px;
}

.no-margin {
  font-size: 20px;
  margin-bottom: 2px;
}

h2 {
  font-size: 40;
  margin-top: 20px;
}

h3 {
  color: rgb(163, 163, 163);
  font-size: 20;
  margin-top: 20px;
  margin-bottom: 10px;
}

h4 {
  color: dimgray;
  margin-top: 200;
}

select {
  width: 100px;
}

button {
  font-family: 'Heebo', sans-serif;
  font-style: bold;
  font-size: 30px;  
  padding: 10px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background-color: #0080ff;
  color: rgb(255, 255, 255);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 20px; 
}

.stop-all-button {
  font-family: 'Heebo', sans-serif;
  font-style: bold;
  font-size: 20px;  
  padding: 10px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background-color: #9a0000;
  color: rgb(255, 255, 255);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 20px; 
}

.stop-all-button:disabled {
  background-color: #4a0000
}

.stop-all-button:hover {
  background-color: #dd0000;
}

button:hover {
  background-color: #38b0ff;
}

button:disabled {
  background-color: #003558;
  cursor: not-allowed; 
  color: #666666; 
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 160px;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-header {
  margin-top: 40px;
  width: 100%;
  padding: 20px 0;
  background-color: #020a20;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
}

.logout-button {
  font-family: 'Heebo', sans-serif;
  font-style: bold;
  font-size: 15px;
  cursor: pointer;
  border: none;
  background-color: #9a0000;
  color: rgb(255, 255, 255);
  border-radius: 20px; 
}

.config-button {
  font-family: 'Heebo', sans-serif;
  font-style: bold;
  font-size: 20px;
  padding: 10px 30px;
  cursor: pointer;
  border: none;
  background-color: #002448;
  color: rgb(255, 255, 255);
}

.delete-account:disabled {
  background-color: #6b4b00; 
  cursor: not-allowed;      
  color: #666666;           
}

.logout-button:hover {
  background-color: #c40000;
}

.logo-container {
  position: absolute; 
  left: 50%;
  transform: translateX(-50%);
}

.top-image {
  width: 200px; /* Set a fixed width for the image */
  height: auto; /* Maintain the aspect ratio */
}

.profile-section {
  display: flex;
  align-items: center;
  position: absolute; /* Position the profile section absolutely */
  right: 20px; /* Adjust the distance from the right edge */
}

.profile-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.profile-text {
  text-align: left;
}

.profile-text h2 {
  margin: 0;
  font-size: 1rem; /* Adjust the font size as needed */
}

.profile-text p {
  margin: 0;
  font-size: '12px'; /* Adjust the font size as needed */
}

.profile-picture {
  margin-top: 20px;
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Maintain aspect ratio */
  margin-right: 10px;
  border-radius: 50%;
}

.account-selection {
  position: absolute;
  left: 565px;
  top: 20px;
}

.dropdown select {
  position: absolute;
  top: 60px;
  left: 100px;
  width: 330px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: #edf2ff;
}

.dropdown-strategies select {
  position: absolute;
  top: 600px;
  left: 60px;
  height: 200;
  width: 1000px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
  background-color: #edf2ff;
}

.large-dropdown {
  width: 250px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  font-size: 16px; /* Adjust the font size as needed */
}

a {
  color: #0080ff;  /* Set the default link color */
  text-decoration: none;  /* Remove underline from links */
}

a:hover {
  color: #55aaff;  /* Set the color for the hover state */
}

a:visited {
  color: #0055a0;  /* Set the color for visited links */
}

a:active {
  color: #0D47A1;  /* Set the color for active links */
}

hr {
  height: 0.5px;
  color: #000000;
}
