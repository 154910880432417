.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000022;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #072c98;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .modal-field {
    margin-bottom: 10px;
  }
  
  .modal-field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-field input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .modal-buttons {
    display: flex;
    font-size: 10px;
    justify-content: space-between;
    margin-top: 20px;
  }

  .confirm-button {
    font-size: small;
  }

  .close-button {
    font-size: small;
  }

  .modal-buttons button:disabled {
    background-color: #cccccc; /* Light gray background */
    color: #666666; /* Dark gray text */
    cursor: not-allowed; /* Change cursor to not-allowed */
    opacity: 0.5; /* Make it semi-transparent */
  }
  