.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: #00000022;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #072c98;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .modal-field {
    margin-bottom: 10px;
  }
  
  .modal-field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-field input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .modal-buttons button:disabled {
    background-color: #cccccc; /* Light gray background */
    color: #666666; /* Dark gray text */
    cursor: not-allowed; /* Change cursor to not-allowed */
    opacity: 0.5; /* Make it semi-transparent */
  }

  .status-icons {
    text-align: center;
    margin-top: 20px;
  }
  
  .fa-spinner, .fa-check-circle {
    font-size: 3em; /* Adjust the size as needed */
  }

  .stop-all-button {
    font-family: 'Heebo', sans-serif;
    font-style: bold;
    font-size: 15px;  
    padding: 10px 40px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    background-color: #9a0000;
    color: rgb(255, 255, 255);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px; 
  }

  .start-all-button {
    font-family: 'Heebo', sans-serif;
    font-style: bold;
    font-size: 15px;  
    padding: 10px 40px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    background-color: #1ca500;
    color: rgb(255, 255, 255);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px; 
  }

  .start-all-button:disabled {
    background-color: #0c4800;
    cursor: not-allowed; 
    color: #666666; 
  }
  